import App, { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { Suspense, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { analytics, basicAuth, i18n } from "shared";
import { Footer, Header } from "../components";
import { AuthProvider } from "../utils/context";
import "../utils/globalStyles.css";

import { appWithTranslation } from "next-i18next";
import { CookiesCard } from "../components/molecules/CookiesCard";

const queryClient = new QueryClient();
const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter();

  useEffect(() => {
    if (window.location.href.startsWith("https://en.kansai")) {
      localStorage.setItem("i18nextLng", "en");
      i18n.changeLanguage("en");
    } else if (window.location.href.startsWith("https://kansai")) {
      localStorage.setItem("i18nextLng", "ja");
      i18n.changeLanguage("ja");
    }

    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url);
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "Kansai",
        });
      };

      routers.events.on("routeChangeComplete", (url) => {
        window.scrollTo(0, 0);
        logEvent(url);
      });

      logEvent(window.location.pathname);
      return () => {
        routers.events.off("routeChangeComplete", logEvent);
      };
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        {/* <meta
          name="viewport"
          user-scalable="yes"
          content="width=device-width, initial-scale=1.0, minimum-scale=0.5, maximum-scale=1.0"
        /> */}
      </Head>

      <AuthProvider>
        <Suspense>
          <Header />
          <Component {...pageProps} />

          <Footer />

          <CookiesCard />
        </Suspense>
      </AuthProvider>
    </QueryClientProvider>
  );
};

MyApp.getInitialProps = async (appContext) => {
  const { req, res } = appContext.ctx;
  const appProps = await App.getInitialProps(appContext);
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "development") {
    basicAuth(req, res);
  }
  return { ...appProps };
};

export default appWithTranslation(MyApp);
