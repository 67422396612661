import { API, i18n } from "shared";

export const GetNewsList = (params) => {
  const city = params?.queryKey[1];
  const locale = params?.queryKey[2];

  return API.get("/users/top-news", {
    params: {
      language: locale || i18n?.language?.startsWith("en") ? "en" : "ja",
      city: city || null,
    },
  });
};
export const GetAllNews = async (params) => {
  const city = params?.queryKey[1];
  const data: any = await API.get("/users/news", {
    params: {
      language: i18n.language.startsWith("en") ? "en" : "ja",
      city: city || null,
    },
  });
  return data;
};
