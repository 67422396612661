import React from "react";
import { styled } from "styled-components";
import { Divider } from "antd";
import { theme } from "shared/src/theme";
import moment from "moment";
import { i18n } from "shared";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  /* box-shadow: 4px 4px 20px rgba(237, 71, 71, 0.08); */
  min-width: 282px;
  max-width: 282px;
  cursor: pointer;

  @media (max-width: 1175px) {
    min-width: 282px;
  }
`;

const StyledLink = styled.a`
  background-color: ${theme.base};
  margin-right: 24px;
  border-radius: 4px;

  &:hover {
    box-shadow: 4px 4px 20px rgba(237, 71, 71, 0.08);
    & .title {
      color: ${theme.buttonRed};
    }
  }
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
`;
const Content = styled.div`
  padding: 8px 16px 16px 16px;

  & .month-year,
  .date,
  a,
  .title {
    color: ${theme.gray0};
  }

  & .month-year {
    margin-bottom: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #191a19;
  }

  & .date {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 0px;
    & span {
      padding-left: 8px;
    }
  }

  & .ant-divider {
    margin: 16px 0px;
    border-color: ${theme.gray3};
  }

  & .title {
    ${theme.typography.titleSmall}
    margin-bottom: 8px;
    display: block;
    &:hover {
      color: ${theme.buttonRed};
    }
  }

  & .tag {
    margin-bottom: 0px;
    ${theme.typography.caption}
    color: #7C837C;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledSmall = styled.small`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #191a19;
  padding: 0px 8px;
`;

// const Placeholder = styled.div`
//   height: 160px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 4px 4px 0px 0px;
//   background-color: ${theme.gray3};
// `;
interface CalenderProps {
  startDate: string;
  endDate: string;
  title: string;
  image: string;
  url: string;
}
export function CalenderCard({
  title,
  image,
  startDate,
  endDate,
  url,
}: CalenderProps) {
  const { t } = useTranslation();

  return (
    <StyledLink href={url} rel="noopener noreferrer" target="_blank">
      <Wrapper>
        <StyledImage
          alt={title}
          src={image || "/default.svg"}
          height={161}
          width={281}
        />
        <Content>
          <p className="month-year">
            {i18n.language.startsWith("ja")
              ? moment(startDate).format("YYYY/MM")
              : `${moment(startDate).format("MMMM")} ${moment(startDate).format(
                  "YYYY"
                )}`}
          </p>
          <Row>
            <p className="date">
              {moment(startDate).format("DD")}
              <StyledSmall>{t(moment(startDate).format("ddd"))}</StyledSmall>
              {endDate &&
                moment(startDate).format("YYYY-MM-DD") !=
                  moment(endDate).format("YYYY-MM-DD") && <span>{" - "}</span>}
              {endDate &&
                moment(startDate).format("YYYY-MM-DD") !=
                  moment(endDate).format("YYYY-MM-DD") && (
                  <span> {moment(endDate).format("DD")} </span>
                )}

              {endDate &&
                moment(startDate).format("YYYY-MM-DD") !=
                  moment(endDate).format("YYYY-MM-DD") && (
                  <StyledSmall>{t(moment(endDate).format("ddd"))}</StyledSmall>
                )}
            </p>
          </Row>
          <Divider />

          <p className="title">{title}</p>
        </Content>
      </Wrapper>
    </StyledLink>
  );
}
