import { createContext, useState } from "react";

type ContextProps = {
  closeDrawer: boolean;
  setCloseDrawer: any;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export const AuthProvider = (props) => {
  const [closeDrawer, setCloseDrawer] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        closeDrawer,
        setCloseDrawer,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
