import { ProgressCard } from "../../atoms";
import { styled } from "styled-components";
import { useEffect } from "react";

const Wrapper = styled.div`
  padding-left: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1550px) {
    margin: 0px 5% 0px 5%;
  }
  @media (max-width: 1440px) {
    margin: 0px 9.02% 0px 11.38%;
  }
  @media (max-width: 1245px) {
    margin: 0px 5% 0px 5%;
    padding-left: 30px;
  }
  @media (max-width: 1150px) {
    margin: 0px 3%;
  }
  @media (max-width: 768px) {
    margin: 0px 16px;
    padding-left: 0px;
    & > div:first-child {
      margin-left: 0px;
    }
  }
`;

const CardWrapper = styled.div`
  position: absolute;
  bottom: 94px;
  max-width: 1440px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    bottom: 68px;
  }
`;

interface Props {
  current: number;
  goTo: any;
  articles: any[];
}

const TopCardContainer = ({ current, goTo, articles }: Props) => {
  const handleScroll = () => {
    if (window.scrollY < 500) {
      document.getElementById(`card${current}`).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    if (window.scrollY < 500 && window.innerWidth < 769) {
      handleScroll();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [current]);

  return (
    <CardWrapper>
      <Wrapper>
        {articles.map((_, index) => (
          <ProgressCard
            length={articles?.length}
            key={index}
            id={`card${index}`}
            selected={current === index}
            onClick={() => goTo(index)}
          />
        ))}
      </Wrapper>
    </CardWrapper>
  );
};

export { TopCardContainer };
