import { theme } from "shared/src/theme";
import { styled } from "styled-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MapPoint } from "../..";

const Wrapper = styled.div`
  padding: 64px 0px 72px 0px;

  & > p {
    max-width: 720px;
    margin: 0px auto 66px auto;
    white-space: break-spaces;
    text-align: center;
  }

  @media (max-width: 875px) {
    display: none;
  }
`;

const Title = styled.h1`
  ${theme.typography.titleLarge}
  text-align: center;
  margin-bottom: 16px;
`;

const MapContainer = styled.div`
  height: 610px;
  width: 762px;
  margin: auto;
  position: relative;
`;

const EcoSystem = () => {
  const { t } = useTranslation();
  const initialValues = {
    point1: false,
    point2: false,
    point3: false,
    point4: false,
    point5: false,
    point6: false,
    point10: false,
    point11: false,
    point12: false,
  };
  const [visible, setVisible] = useState({
    ...initialValues,
  });

  const handlePointChange = (name: string, visible: boolean) => {
    setVisible({ ...initialValues, [name]: visible });
  };

  return (
    <>
      <Wrapper>
        <Title>{t("KANSAI Startup Ecosystem")}</Title>
        <p>
          {t(
            "The Union of Kansai Governments, in cooperation with the Osaka-Kyoto-Hyogo-Kobe Consortium, will promote support for start-ups by taking advantage of the common points and strengths of the region."
          )}
        </p>
        <MapContainer>
          <img src="/map.svg" alt={t("KANSAI Startup Ecosystem")} />
          <MapPoint
            name="point1"
            title={t("Tottori")}
            slug={"tottori"}
            setVisible={handlePointChange}
            visible={visible.point1}
            image=""
            description=""
            left={267}
            top={182}
          />
          <MapPoint
            name="point2"
            visible={visible.point2}
            setVisible={handlePointChange}
            title={t("Tokushima")}
            slug={"tokushima"}
            image=""
            placement="bottom"
            description=""
            bottom={152}
            left={291}
          />
          <MapPoint
            name="point3"
            visible={visible.point3}
            setVisible={handlePointChange}
            title={t("HyogoKobe（Hyogo Prefecture and Kobe City）")}
            slug={"hyogo_kobe"}
            image=""
            description=""
            top={306}
            left={388}
          />
          <MapPoint
            name="point4"
            visible={visible.point4}
            setVisible={handlePointChange}
            title={t("Osaka (Osaka Prefecture and Osaka City)")}
            slug={"osaka"}
            image=""
            description=""
            top={308}
            right={275}
          />
          <MapPoint
            name="point5"
            visible={visible.point5}
            placement="right"
            setVisible={handlePointChange}
            title={t("Sakai")}
            slug={"sakai"}
            image=""
            description=""
            top={357}
            right={283}
          />
          <MapPoint
            name="point6"
            visible={visible.point6}
            setVisible={handlePointChange}
            title={t("Wakayama")}
            slug={"wakayama"}
            placement="bottomLeft"
            image=""
            description=""
            bottom={186}
            right={315}
          />
          <MapPoint
            name="point10"
            visible={visible.point10}
            setVisible={handlePointChange}
            title={t("Shiga")}
            slug={"shiga"}
            image=""
            description=""
            top={262}
            right={194}
          />
          <MapPoint
            name="point11"
            visible={visible.point11}
            setVisible={handlePointChange}
            title={t("Kyoto (Kyoto Prefecture and Kyoto City)")}
            slug={"kyoto"}
            image=""
            description=""
            top={270}
            right={229}
          />
          <MapPoint
            name="point12"
            visible={visible.point12}
            setVisible={handlePointChange}
            title={t("Nara")}
            slug={"nara"}
            image=""
            description=""
            top={330}
            right={220}
          />
        </MapContainer>
      </Wrapper>
    </>
  );
};

export { EcoSystem };
