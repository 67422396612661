import { styled } from "styled-components";
import { useEffect, useState } from "react";

interface Props {
  src: string;
  preview: string;
  height?: number | string;
  width?: number | string;
  alt?: string;
  className?: string;
}

const Img = styled.img`
  &.loading {
    filter: blur(25px);
    transition: 0.5s filter linear;
  }
`;

const ProgressiveImage = ({
  src,
  preview,
  height,
  width,
  alt,
  className,
}: Props) => {
  const [currentImage, setCurrentImage] = useState(preview);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => {
      setLoading(false);
      setCurrentImage(src);
    };
  }, [src]);

  return (
    <Img
      className={`${loading && "loading"} ${className}`}
      src={currentImage}
      height={height}
      width={width}
      alt={alt}
    />
  );
};

export { ProgressiveImage };
