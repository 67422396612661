const titleLarge = {
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "40px",
};

const headline1 = {
  fontWeight: "600",
  fontSize: "34px",
  lineHeight: "40px",
  letterSpacing: "0.0025em",
};

const headline2 = {
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "32px",
  letterSpacing: "0.0025em",
};

const subtitle1 = {
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "32px",
  letterSpacing: " 0.0025em",
};

const subtitle2 = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: " 0.0015em",
};

const body = {
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: " 0.0015em",
};

const bodySmall = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.0015em",
};

const titleSmall = {
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "21px",
};

const caption = {
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "22px",
};

const captionSmall = {
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "22px",
};

const paragraph = {
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "32px",
};

const typographyH1 = {
  fontWeight: "bold",
  fontSize: "44px",
  lineHeight: "73px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
};

const typographyMap = {
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "30px",
  letterSpacing: "0.02em",
  fontFamily: "Roboto,'Noto Sans JP'",
};

const typographyH2 = {
  fontWeight: 900,
  fontSize: 30,
  lineHeight: "38px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
};

const typographyH2EN = {
  fontWeight: 900,
  fontSize: 18,
  lineHeight: "16px",
  letterSpacing: "0.05em",
  fontFamily: "Roboto",
};

const typographyH3 = {
  fontSize: "20px",
  fontWeight: "bold",
  lineHeight: "15px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
};

const typographyPrimary = {
  fontSize: 16,
  lineHeight: "30px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
};
const typographyPrimaryBold = {
  fontSize: 16,
  lineHeight: "30px",
  fontWeight: "bold",
  letterSpacing: "0.03 em",
  fontFamily: "Roboto",
};

const typographySecondary = {
  fontSize: 14,
  lineHeight: "20px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
};

const typographySecondaryBold = {
  fontSize: 14,
  fontWeight: "bold",
  lineHeight: "20px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
};

const typographyTertiary = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
};

const typographyTertiaryBold = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
  fontWeight: "bold",
};
const typographyTag = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
  fontWeight: "normal",
};
const typographyMapTag = {
  fontSize: 12,
  lineHeight: "30px",
  fontFamily: "Roboto,'Noto Sans JP'",
  fontWeight: "bold",
};

const typographyMB = {
  fontWeight: 900,
  fontSize: 26,
  lineHeight: "28px",
  letterSpacing: "0.02em",
  fontFamily: "Roboto",
};
const typographyMBH2 = {
  fontWeight: 900,
  fontSize: 22,
  lineHeight: "35px",
  letterSpacing: "0.01em",
  fontFamily: "Roboto",
};
export const theme = {
  black: "#000000",
  strokeGray: "#4B4E4B",
  title: "#16222A",
  disable: "#E6E6E6",
  gray2: "#C6C6C6",
  grey2: "#7C837C",
  gray: "#EEEEEE",
  gray1: "#4B4E4B",
  gray0: "#191A19",
  gray3: "#CBCDCB",
  buttonRed: "#ED4747",
  gray7: "#8C8C8C",
  gray4: "#F2F3F3",
  adminBg: "#001529",
  gray8: "#595959",
  gray9: "#262626",
  gray10: "#7C837C",
  primary: "#121212",
  link: "#1890ff",
  signupLink: "#19389C",
  secondaryLight: "#C2C2C2",
  secondaryDark: "#8E8E8E",
  yellow1: "#F7CD48",
  yellow2: "#EAB82D",
  blue1: "#19389C",
  blue2: "#1751D5",
  blue4: "#2B478B",
  accentMid: "#F5FAFF",
  base: "#FFFFFF",
  primaryLine: "#DCDCDC",
  face1: "#FFB27D",
  face2: "#E2915A",
  alert: "#EB5757",
  lightBlue: "#B9CBF2",
  white: "#ffffff",
  textColor: "#16222A",
  grayBackground: "#F3F3F3",
  info: "#0B6DDD",
  typography: {
    typographyH1,
    typographyH2,
    typographyH2EN,
    typographyH3,
    typographyPrimary,
    typographyPrimaryBold,
    typographySecondary,
    typographySecondaryBold,
    typographyTertiary,
    typographyTertiaryBold,
    typographyMB,
    typographyMBH2,
    typographyTag,
    typographyMapTag,
    typographyMap,
    caption,
    captionSmall,
    titleSmall,
    titleLarge,
    paragraph,
    body,
    bodySmall,
    headline1,
    subtitle2,
    headline2,
    subtitle1,
  },
};
