import { styled } from "styled-components";
import { theme } from "shared/src/theme";
import { useTranslation } from "react-i18next";
import { Button } from "shared";
import { Carousel } from "antd";
import { useRef } from "react";
import { useRouter } from "next/router";

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
const Wrapper = styled.div`
  min-height: 814px;
  max-height: 814px;
  position: relative;
  & .ant-carousel {
    & > img {
      object-fit: cover;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    height: 620px;
    min-height: auto;
  }
`;

const Overlay = styled.div`
  background-color: ${theme.title};
  height: 814px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  opacity: 0.65;
  @media (max-width: 768px) {
    height: 620px;
  }
`;

const Content = styled.div`
  position: relative;
  padding-left: 58px;
  z-index: 1;
  & button {
    width: 138px;
    min-height: 48px;
    padding: 0px;
    margin-bottom: 93px;
  }
  @media (max-width: 1550px) {
    margin: 0px 5% 0px 5%;
  }
  @media (max-width: 1440px) {
    margin: 0px 9.02% 0px 11.38%;
  }
  @media (max-width: 1245px) {
    margin: 0px 5% 0px 5%;
    padding-left: 30px;
  }
  @media (max-width: 1150px) {
    margin: 0px 3% 0px 3%;
  }

  @media (max-width: 768px) {
    margin: 0px 16px;
    padding-left: 0px;
  }
`;

const InfoTitle = styled.h1`
  margin-top: 298px;
  max-width: 486px;
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
  color: ${theme.base};
  margin-bottom: 24px;
  white-space: pre-wrap;
  @media (max-width: 768px) {
    margin-top: 227px;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 24px;
    letter-spacing: 0.0025em;
  }
  @media (max-width: 545px) {
    max-width: 350px;
  }
  @media (max-width: 375px) {
    max-width: 262px;
  }
`;

const Description = styled.p`
  max-width: 486px;
  font-style: italic;
  ${theme.typography.subtitle2}
  color: ${theme.base};
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1440px;
  margin: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
`;

const Slide = styled.div`
  position: relative;
  & img {
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    & img {
      height: 620px;
    }
  }
`;

const Top = () => {
  const { t } = useTranslation();
  const sliderRef = useRef(null);
  const router = useRouter();

  const DUMMY_ARTICLES = [
    {
      image: "1.JPG",
      webp: "1.webp",
      preview: "1-thumb.jpg",
    },
    {
      image: "2.jpg",
      webp: "2.webp",
      preview: "2-thumb.jpg",
    },
    {
      image: "3.JPG",
      webp: "3.webp",
      preview: "3-thumb.jpg",
    },
    {
      image: "4.jpg",
      webp: "4.webp",
      preview: "4-thumb.jpg",
    },
    {
      image: "5.JPG",
      webp: "5.webp",
      preview: "5-thumb.jpg",
    },
    {
      image: "6.jpg",
      webp: "6.webp",
      preview: "6-thumb.jpg",
    },
    {
      image: "7.JPG",
      webp: "7.webp",
      preview: "7-thumb.jpg",
    },
    {
      image: "8.jpg",
      webp: "8.webp",
      preview: "8-thumb.jpg",
    },
    {
      image: "9.jpg",
      webp: "9.webp",
      preview: "9-thumb.jpg",
    },
    {
      image: "10.JPG",
      webp: "10.webp",
      preview: "10-thumb.jpg",
    },
  ];

  return (
    <>
      <Wrapper>
        <ContentWrapper>
          <Content>
            <InfoTitle>
              {t("Kansai Startup,\nDeep tech Valley KANSAI")}
            </InfoTitle>
            <Description>
              {t(
                '"Kansai" has possibilities to create new industries based on it.'
              )}
            </Description>
            <Button type="primary" onClick={() => router.push("/about")}>
              {t("Read more")}
            </Button>
          </Content>
        </ContentWrapper>
        <Carousel
          fade
          autoplay
          ref={sliderRef}
          speed={5000}
          autoplaySpeed={100}
          slidesToShow={1}
          slidesToScroll={1}
          dots={false}
          infinite
          pauseOnHover={false}
        >
          {DUMMY_ARTICLES.map((article, index) => (
            <Slide key={index}>
              <Overlay />
              <picture>
                <source type="image/webp" srcSet={`/top/${article?.webp}`} />

                <img
                  src={`/top/${article?.image}`}
                  height="814"
                  width="1440"
                  alt="articles"
                />
              </picture>
              {/* <ProgressiveImage
                preview={`/top/${article?.preview}`}
                src={`/top/${article?.image}`}
                height="814"
                width="1440"
                alt="articles"
              /> */}
            </Slide>
          ))}
        </Carousel>
      </Wrapper>
    </>
  );
};

export { Top };
