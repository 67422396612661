import { styled } from "styled-components";
import { Collapse } from "antd";

import DownIcon from "../../../assets/downIcon.svg";
import UpIcon from "../../../assets/upIcon.svg";
import { SupportCardDetails } from "../../molecules/SupportCardDetails";

const { Panel } = Collapse;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 40px;

  @media (max-width: 625px) {
    grid-template-columns: 1fr;
  }
`;

const Desktop = styled.div`
  margin-top: 20px;

  @media (max-width: 915px) {
    display: none;
  }
`;
interface Props {
  title: string;
  icon?: any;
  lists: any[];
  id: string;
}

const SupportCardList = ({ title, icon, lists, id }: Props) => {
  return (
    <>
      <Desktop>
        <h1 id={id}>
          {icon && icon}
          {title}
        </h1>
        <Grid>
          {lists &&
            lists.map((item, index) => (
              <SupportCardDetails key={index} {...item} />
            ))}
        </Grid>
      </Desktop>
      <Collapse
        defaultActiveKey={["menu0"]}
        ghost
        expandIcon={({ isActive }) => (isActive ? <UpIcon /> : <DownIcon />)}
        expandIconPosition="right"
      >
        <Panel
          header={<span className="panel-title">{title}</span>}
          key={id}
          extra={icon}
        >
          <Grid>
            {lists &&
              lists.map((item, index) => (
                <SupportCardDetails key={index} {...item} />
              ))}
          </Grid>
        </Panel>
      </Collapse>
    </>
  );
};

export { SupportCardList };
