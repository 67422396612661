import { Button } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";
import { theme } from "../../../theme";

type ButtonType = "primary" | "link";
export interface ButtonProps {
  children?: React.ReactNode;
  htmlType?: "button" | "submit" | "reset";
  type: ButtonType;
  width?: string;
  block?: boolean;
  padding?: string;
  loading?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  minheight?: number;
  minwidth?: number;
  boxshadow?: string;
  background?: string;
  borderradius?: string;
  noradius?: boolean;
  typography?: any;
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  bold?: boolean;
  margin?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  bordercolor?: string;
  className?: string;
}

const Wrapper = styled.div`
  display: contents;
  font-family: "Open Sans", sans-serif;
  & .ant-btn-primary {
    background: ${theme.buttonRed};
    border-color: ${theme.buttonRed};
    box-shadow: none;

    line-height: 21px;
    height: auto;
    transition: all 0.5s;

    &:hover {
      background: #cb3341;
      border: 1px solid darken #cb3341;
    }
  }

  & .ant-btn-link {
    box-shadow: none;
    background: transparent;
    color: ${theme.blue2};
    height: auto;
  }
`;
const StyledButton = styled(Button)`
  &[disabled] {
    background-color: ${theme.secondaryLight};
    border: none;
    box-shadow: none !important;
    color: ${theme.base};

    &:hover {
      background: #a6a6a6;
      color: ${theme.base};
      border: 1px solid darken #cb3341;
    }
  }
  border-color: ${theme.blue2};
  border-radius: ${({ borderradius, noradius }: ButtonProps) => {
    return noradius ? "none" : borderradius ? borderradius : "10px";
  }};
  margin: ${({ margin }: ButtonProps) => {
    return margin ? margin : "unset";
  }};
  padding: ${({ type, padding }: ButtonProps) => {
    if (padding) {
      return `${padding} !important`;
    }
    switch (type) {
      case "primary":
        return "15px 53px";
      default:
        return "15px 53px";
    }
  }};

  background: ${({ background }: ButtonProps) => {
    return background ? background : theme.blue2;
  }};

  color: ${({ color }: ButtonProps) => {
    return color ? `${color} !important` : theme.base;
  }};
  font-size: ${({ fontSize }: ButtonProps) => {
    return fontSize ? fontSize : "16px";
  }};
  font-weight: ${({ fontWeight }: ButtonProps) => {
    return fontWeight ? fontWeight : `600`;
  }};
  min-width: ${({ minwidth }: ButtonProps) => {
    return minwidth ? `${minwidth}px` : "unset";
  }};
  width: ${({ width }: ButtonProps) => {
    return width ? `${width}` : "auto";
  }};
  min-height: ${({ minheight }: ButtonProps) => {
    return minheight ? `${minheight}px` : "unset";
  }};
`;

export const ButtonComponent: React.FC<ButtonProps> = ({
  children,
  htmlType,
  loading,
  onClick,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <StyledButton
        onClick={onClick}
        htmlType={htmlType}
        loading={loading}
        {...rest}
      >
        {children}
      </StyledButton>
    </Wrapper>
  );
};
