import React from "react";
import { Button, PageHeader as AntdPageHeader } from "antd";
import styled from "styled-components";
import { useRouter } from "next/router";
import { theme } from "../../../theme";
import Head from "next/head";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
interface Props {
  title: string;
  noBack?: boolean;
  actionHref?: string;
  onClick?: any;
  pageTitle?: string;
}

const PageHeader = styled(AntdPageHeader)`
  background: ${theme.base};
  z-index: 1200;
  position: sticky;
  top: 0px;
  height: 64px;
  min-height: 64px;
  box-shadow: 0px 0px 2px 0px #3535352e;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  & .ant-page-header-heading {
    width: 100%;
  }
  & .ant-page-header-heading-title {
    font-size: 20px;
    font-weight: normal;
    line-height: 28px;
    color: ${theme.gray9};
  }
  & .ant-page-header-heading-extra {
    margin-left: auto;
  }
`;

const HeaderWrapper = styled.div`
  background: ${theme.base};
  position: sticky;
  top: 0px;
  z-index: 1200;
`;

const PageHeaderComponent = ({
  title,
  noBack,
  onClick,
  actionHref,
  pageTitle,
}: Props) => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <Head>
        <title>
          {t(`{{pageTitle}} - Kansai Startup. Ecosystem`, {
            pageTitle: pageTitle ? pageTitle : title,
          })}
        </title>
      </Head>
      <PageHeader
        onBack={noBack ? null : onClick ? onClick : () => router.back()}
        title={title}
        extra={
          actionHref ? (
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => router.push(actionHref)}
            >
              {t("Add")}
            </Button>
          ) : null
        }
      />
    </HeaderWrapper>
  );
};

export { PageHeaderComponent };
