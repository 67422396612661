import { i18n } from "../i18n";

const PREFECTURE_OPTIONS = [
  { label: i18n.t("Osaka (Osaka Prefecture and Osaka City)"), value: "1" },
  { label: i18n.t("Sakai"), value: "2" },
  { label: i18n.t("HyogoKobe（Hyogo Prefecture and Kobe City）"), value: "3" },
  { label: i18n.t("Kyoto (Kyoto Prefecture and Kyoto City)"), value: "4" },
  { label: i18n.t("Shiga"), value: "5" },
  { label: i18n.t("Wakayama"), value: "6" },
  { label: i18n.t("Tokushima"), value: "7" },
  { label: i18n.t("Tottori"), value: "8" },
  { label: i18n.t("Nara"), value: "9" },
];

export { PREFECTURE_OPTIONS };
