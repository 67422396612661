import React from "react";
import { styled } from "styled-components";
import { useInfiniteQuery } from "react-query";
import { GetSimilarArticles } from "../../../service/article";
import { Spin } from "antd";
import ArticleCard from "../../molecules/ArticleCard";
import { theme } from "shared/src/theme";
import { useTranslation } from "react-i18next";
import { Button, PREFECTURE_OPTIONS } from "shared";

const Wrapper = styled.section`
  max-width: 1440px;
  margin: auto;
  padding-bottom: 65px;

  & button {
    display: block;
    margin: 40px auto 0px auto;
    padding: 10px;
    min-width: 121px;
    min-height: 48px;
  }

  @media (max-width: 1550px) {
    padding: 0px 5% 65px 5%;
  }
  @media (max-width: 1440px) {
    padding: 0px 9.02% 65px 8.33%;
  }
  @media (max-width: 1245px) {
    padding: 0px 5% 64px 5%;
  }
  @media (max-width: 1150px) {
    padding: 0px 3% 64px 3%;
    min-height: auto;
  }
`;

const StyledTitle = styled.h2`
  ${theme.typography.titleLarge}
  margin-bottom: 32px;
  color: ${theme.gray0};
`;

const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 24px;

  @media (min-width: 1441px) {
    grid-column-gap: 40px;
  }

  @media (max-width: 875px) {
    grid-template-columns: 1fr 1fr;
    height: auto;
  }

  @media (max-width: 575px) {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: unset;
    grid-column-gap: unset;

    & > div:first-child {
      width: 100%;
      & img {
        max-height: 227px;
      }

      & .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
      }
      margin-bottom: 32px;
    }

    & > div:nth-child(2),
    & > div:nth-child(3) {
      margin-bottom: 30px;
      width: calc(50% - 14px);
      & img {
        max-height: 132px;
      }
    }
    & > div:nth-child(2) {
      margin-right: 7.5px;
    }
    & > div:nth-child(3) {
      margin-left: 7.5px;
    }

    & > div:nth-child(n + 4) {
      margin-bottom: 16px;
      width: 100%;
      & .ant-card {
        display: flex;
      }
      & img {
        max-height: 96px;
        min-height: 96px;
        max-width: 96px;
        border-radius: 10px 0px 0px 10px !important;
        min-width: 96px;
        margin-right: 16px;
      }
      & .ant-card-body {
        padding: 0px;
      }
    }

    & > div:last-child {
      margin-bottom: 0px;
    }
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 16vh;
  @media (max-width: 769px) {
    height: auto;
  }
`;

interface Props {
  slug: string | string[];
}
export const spreadArray = (data?: any[]): any[] => {
  const newArray: any[] = [];
  if (data) {
    for (const row of data) {
      const rowData = row?.data || [];
      for (const innerData of rowData) {
        newArray.push(innerData);
      }
    }
  }
  return newArray;
};
const SimilarArticles = ({ slug }: Props) => {
  const { t } = useTranslation();

  const getCount = (allData) => {
    let sum = 0;
    for (const page of allData) {
      sum += page?.data?.length || 0;
    }
    return sum;
  };
  const GetPrefecture = (code) => {
    const name = PREFECTURE_OPTIONS?.find((pre) => pre.value === code)?.label;
    return name;
  };

  const {
    data: articles,
    isLoading,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery<any, any>(
    ["get-similar-articles", slug],
    GetSimilarArticles,
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage: any, allData) => {
        const gotCount = getCount(allData);
        const totalCount = lastPage.count;

        if (gotCount < totalCount) {
          return allData.length + 1;
        }
      },
    }
  );

  if (isLoading || articles?.pages[0]?.count === 0) {
    return <></>;
  }

  return (
    <Wrapper>
      <StyledTitle>{t("Browse similar articles")}</StyledTitle>

      <ArticleContainer>
        {articles &&
          spreadArray(articles?.pages)?.map((item, index) => (
            <ArticleCard
              slug={item.slug}
              key={index}
              hasVideo={item.hasVideo}
              image={item.coverImage}
              title={item.title}
              name={GetPrefecture(item?.city?.toString())}
            />
          ))}
      </ArticleContainer>
      {isLoading ||
        (isFetching && (
          <LoadingWrapper>
            <Spin size="large" />
          </LoadingWrapper>
        ))}
      {!(isLoading || isFetching) &&
        articles?.pages[0]?.count != spreadArray(articles?.pages)?.length && (
          <Button
            onClick={() => {
              fetchNextPage();
            }}
            type="primary"
          >
            {t("Show Other Articles")}
          </Button>
        )}
    </Wrapper>
  );
};

export { SimilarArticles };
