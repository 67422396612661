import { Popover } from "antd";
import { styled } from "styled-components";
import { Button } from "shared";
import { theme } from "shared/src/theme";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

const CardWrapper = styled.div`
  display: flex;
  width: 200px;
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  & h1 {
    ${theme.typography.titleLarge}
    margin-bottom: 7px;
  }
  & p {
    ${theme.typography.caption}
  }
  & img {
    border-radius: 10px;
    margin-right: 16px;
  }
  & button {
    padding: 0px;
    height: 40px !important;
    width: 100%;
    margin-top: auto;
  }
`;

const Point = styled.div<{
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  visible: boolean;
}>`
  width: 18px;
  height: 18px;
  background-color: ${theme.buttonRed};
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  left: ${({ left }) => (left ? `${left}px` : "unset")};
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "unset")};
  top: ${({ top }) => (top ? `${top}px` : "unset")};
  right: ${({ right }) => (right ? `${right}px` : "unset")};
  transition: all 0.1s ease-in;
  border: ${({ visible }) =>
    visible ? "4px solid white" : "2.5px solid #f3a3a2"};
  &:hover {
    transition: all 0.1s ease-in;
    border: 4px solid white;
  }
`;

interface Props {
  name?: string;
  title: string;
  description: string;
  image: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  visible?: boolean;
  setVisible?: any;
  placement?: any;
  slug?: string;
}

const MapPoint = ({
  name,
  title,
  description,
  image,
  top,
  bottom,
  left,
  right,
  visible,
  setVisible,
  placement,
  slug,
}: Props) => {
  const { t } = useTranslation();
  const router = useRouter();

  const content = (
    <CardWrapper>
      {image != "" && (
        <img alt="kansai map" src={image} height={182} width={156} />
      )}
      <div>
        <h1>{title}</h1>
        <p>{description}</p>
        <Button
          type="primary"
          onClick={() => router.push(`/city/${slug}`)}
          disabled={slug === ""}
        >
          {t("Go to city")}
        </Button>
      </div>
    </CardWrapper>
  );
  return (
    <Popover
      visible={visible}
      placement={placement}
      onVisibleChange={(visible) => setVisible(name, visible)}
      overlayClassName="map-popover"
      content={content}
      title={null}
    >
      <Point
        visible={visible}
        left={left}
        top={top}
        right={right}
        bottom={bottom}
      />
    </Popover>
  );
};

export { MapPoint };
