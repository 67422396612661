import React from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import { MenuGenerator } from "../../atom/MenuGenerator";

const Wrapper = styled.div`
  position: sticky;
  top: 0px;
  & ul {
    border-right: none;
  }
  & li {
    width: 100% !important;
  }

  & li:after {
    border-right: none !important;
  }
  .ant-menu {
    height: calc(100vh - 79px);
  }
  .ant-menu-item,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    opacity: 0.65;
  }
  .active,
  .ant-menu-item:hover {
    color: #0c0f11 !important;
    background: #a9d5e9 !important;
    border-right: 3px solid;
  }
`;

const SideNavHeadWrapper = styled.div`
  padding: 14px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #ccc;

  padding-bottom: 19px;
  background: #ffffff;
  & img {
    width: 110px;
  }
`;

interface Props {
  menu: any[];
  href: string;
}

const DashboardSideNav = ({ menu, href }: Props) => {
  const router = useRouter();

  const selectKey = router.pathname.split("/");
  if (selectKey[1] === "menu1-submenu1") selectKey[1] = "menu1";
  const selectKeyDefault = selectKey[1] || "menu1";

  return (
    <Wrapper>
      <SideNavHeadWrapper onClick={() => router.push(href)}>
        <img src={"/logo.svg"} alt="kansai Logo" />
      </SideNavHeadWrapper>
      <MenuGenerator
        mode="inline"
        defaultSelectedKeys={[selectKeyDefault]}
        items={menu}
      />
    </Wrapper>
  );
};

export { DashboardSideNav };
